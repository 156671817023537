import { GET, POST, POST2, POST3 } from "@/api/http";
let baseUrl = ['/api1','/api2','/api3','/api4']
if (process.env.NODE_ENV === 'production') { //生产环境
    baseUrl = [
        "https://api.geom.cn",
        "https://www.geom.cn",
        "http://129.211.106.132:8082",
        "https://api.geom.cn",
        
    ]; //接口域名修改地址 
    // console.log(baseUrl);
}
export default {
    /*个人信息相关*/
    getwechatQrcodeNew: p => GET('/api/wechat/getwechatQrcode', p, baseUrl[0]), //登录二维码
    loopNew: p => POST('/api/wechat/loop', p, baseUrl[0]), //轮训是否扫描二维码
    getUserInfoNew: p => POST('/api/account/getUserInfo', p, baseUrl[1]), //获取个人信息
    getloginNew: p => POST('/api/account/login', p, baseUrl[0]), //账号密码登录
    getsmsCodeNew: p => POST('/api/sms/smsCode', p, baseUrl[0]), //获取手机验证码
    registerAccountNew: p => POST('/api/account/registerAccount', p, baseUrl[0]), //注册账号
    setpasswordNew: p => POST('/api/account/password', p, baseUrl[0]), //重置密码
    getfileNew: p => POST('/api/file/file', p, baseUrl[0]),
    verifyUserNew: p => POST('/api/account/verifyUser', p, baseUrl[0]), //检测手机号是否注册
    bindingNew: p => POST('/api/account/binding', p, baseUrl[0]), //绑定手机号

    saveAnalysis: p => POST('/api/texture/saveAnalysis', p, baseUrl[1]), // 统计浏览量
    findAdvertisementsById: p => POST('/api/geomfrog/findAdvertisementsById', p, baseUrl[2]), // 统计浏览量

    invitedCode: p => POST('/api/team/verifyInvitedcode', p, baseUrl[1]), // 邀请码
    teamMember: p => POST('/api/team/teamMember', p,  baseUrl[1]), // 加入项目
    
   
    

}