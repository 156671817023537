import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'geom',
        component: () => import(/* webpackChunkName: "home" */ '../views/geom/geomWeb.vue'),
        meta: { title: '几何云官网'}
        
    },
    {
        path: '/IndividualRegist',
        name: 'IndividualRegist',
        component: () => import(/* webpackChunkName: "home" */ '../views/geom/IndividualRegist.vue'),
        meta: { title: '几何云官网'}
        
    },
    {
        path: '/download',
        name: 'download',
        component: () => import(/* webpackChunkName: "home" */ '../views/geom/download.vue'),
        meta: { title: '下载'}
    },





    // h5
    {
        path: '/h5',
        name: 'h5',
        component: () => import(/* webpackChunkName: "home" */ '../views/h5geom/h5geomWeb.vue'),
        meta: { title: '几何云官网'}
    },
    {
        path: '/IndividualRegistH5',
        name: 'IndividualRegistH5',
        component: () => import(/* webpackChunkName: "home" */ '../views/h5geom/IndividualRegistH5.vue'),
        meta: { title: '个人注册'}
    },
    {
        path: '/downloadH5',
        name: 'downloadH5',
        component: () => import(/* webpackChunkName: "home" */ '../views/h5geom/downloadH5.vue'),
        meta: { title: '下载'}
    },
    {
        path: '/recommended',
        name: 'recommended',
        component: () => import(/* webpackChunkName: "home" */ '../views/h5geom/recommended.vue'),
        meta: { title: '下载'}
    },

    // 广告
    
    {
        path: '/ubland',
        name: 'ubland',
        component: () => import(/* webpackChunkName: "home" */ '../views/ubland/ubland.vue'),
        meta: { title: '几何云'}
    },
    {
        path: '/ublandH5',
        name: 'ublandH5',
        component: () => import(/* webpackChunkName: "home" */ '../views/ubland/ublandH5.vue'),
        meta: { title: '几何云'}
    },

    // 邀请项目
    {
        path: '/Invite',
        name: 'Invite',
        component: () => import(/* webpackChunkName: "home" */ '../views/share/Invite.vue'),
        meta: { title: '几何云'}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
